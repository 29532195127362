import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  /* .inner { */
  /*   display: grid; */
  /*   grid-template-columns: 3fr 2fr; */
  /*   grid-gap: 50px; */

  /*   @media (max-width: 768px) { */
  /*     display: block; */
  /*   } */
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const technologies = [
    'Flask',
    'Express',
    'Node.js',
    'NextJS',
    'FastAPI',
    'Django',
    'MongoDB',
    'PostgreSQL',
    'Docker',
    'NGINX',
    'Kibana',
  ];

  const languages = [
    'Python',
    'C++',
    'Java',
    'JavaScript (ES6+)',
    'TypeScript',
    'HTML/CSS',
    'Rust',
    'Clojure',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello ! I'm Minh Tran, a Computer Engineering student at Concordia University,
              Montreal, Canada. I'm most passionate about designing systems that scales but I'm also
              interested in compilers, system programming and various programming languages. When
              I'm not coding, I read books, listen to podcasts or study music theory.
            </p>

            <p>Here are a few technologies I’ve been working with recently:</p>
          </div>

          <ul className="skills-list">
            {technologies && technologies.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>

          <p>And here are the programming languages that I've learned:</p>

          <ul className="skills-list">
            {languages && languages.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default About;
